<template>
  <div id="check-email-modal" class="modal fade">
    <!-- TODO maybe need delete it -->
    <div class="modal-dialog modal-dialog-centered mobile-modal-dialog">
      <div class="modal-content modal-container rounded-0 boredet-0">
        <div class="modal-header border-0 p-0 flex-column align-items-start">
          <h5 class="modal-title seccondary-title-modal mb-0">
            {{ $t('auth.check_your_email') }}
          </h5>
          <p class="modal-description">
            {{ $t('auth.instructions') }}
          </p>
          <button
            type="button"
            class="btn border-0 bg-transparent shadow-none p-0 modal-close position-absolute"
          >
            <img src="~/assets/images/close-btn.svg" alt="close" width="12" height="12" data-not-lazy />
          </button>
        </div>
        <div class="modal-body p-0">
          <form @submit.prevent="sendButton">
            <button
              aria-label="I got it"
              type="submit"
              class="btn secondary-btn shadow-none rounded-0 d-inline-flex align-items-center justify-content-center text-uppercase w-100"
            >
              {{ $t('auth.i_go_it') }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalCheckEmail',

  methods: {
    sendButton () {
      console.log('ok')
    }
  }
}
</script>
